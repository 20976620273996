import { Edit, ShoppingCartCheckout, AddShoppingCart, DeleteForever, Engineering } from "@mui/icons-material";
import { callEditBranch, deleteAnyFile, uploadAnyFile } from "../../assets/js/firebaseMethods";
import { defaultDataBranch, deleteDataBranch } from "./SpeedDialButton";
import { deleteDoc, doc, updateDoc } from "firebase/firestore";
import { deleteCollections } from "../../assets/js/Commons";
import ModalFullScreen from "../containers/ModalFullScreen";
import { db } from "../../assets/context/firebase-config";
import { useThis } from "../../assets/context/Context";
import { useEffect, useRef, useState } from "react";
import getConstant from "../../assets/js/Constant";
import endpoints from "../../assets/js/Endpoints";
import { Divider, Menu } from "@mui/material";
import Subscriptions from "./Subscriptions";
import DeleteBranch from "./DeleteBranch";
import CreateBranch from "./CreateBranch";
import Modal from "../containers/Modal";
import ManageUsers from "./ManageUsers";
import Message from "./Message";
import Item from "./Item";
import { v4 } from "uuid";

/**
 * @name BranchCardMenu
 * @description Método encargado de devolver un componente BranchCardMenu (Opciones: Suscripciones, usuarios, editar y eliminar sucursales)
 * @param {Object} { anchorEl, setAnchorEl, consecutive, id, administrator }
 * @returns View
 * @version 1.0
 */
const BranchCardMenu = ({ anchorEl, setAnchorEl, consecutive, id, administrator }) => {
  const css = styles();
  const constant = getConstant();
  const open = Boolean(anchorEl);
  const { user, lang, snapshotBranches, dataBranch, setDataBranch, defalutBranch, entryType } = useThis();

  const [snack, setSnack] = useState([]); // [Índice 0 = código del mensaje, Índice 1 = tipo de mensaje]
  const [loading, setLoading] = useState(false);
  const [iconIndex, setIconIndex] = useState(0);
  const [data, setData] = useState(constant.branchCode);
  const [img, setImg] = useState({ logo: "", logoName: "" });
  const [openModalToDelete, setOpenModalToDelete] = useState(false);
  const [openModalManageUsers, setOpenModalManageUsers] = useState(false);
  const [openModalToEditBranch, setOpenModalToEditBranch] = useState(false);
  const [openModalSubscriptions, setOpenModalSubscriptions] = useState(false);

  const ideFocus = useRef(null);
  const nameFocus = useRef(null);
  const emailFocus = useRef(null);

  /**
   * @name handleClose
   * @description Método que ejecuta el evento para cerrar el menú
   * @version 1.0
   */
  const handleClose = () => setAnchorEl(null);

  /**
   * @name showModalToDeleteBranch
   * @description Método encargado de mostrar el modal para eliminar la sucursal
   * @version 1.0
   */
  const showModalToDeleteBranch = () => setOpenModalToDelete(true);

  /**
   * @name showModalSubscriptions
   * @description Método encargado de mostrar el modal de suscripciones
   * @version 1.0
   */
  const showModalSubscriptions = () => setOpenModalSubscriptions(true);

  /**
   * @name showModalSubscriptions
   * @description Método encargado de mostrar el modal de suscripciones
   * @version 1.0
   */
  const showModalMangeUsers = () => setOpenModalManageUsers(true);

  /**
   * @name deleteBranch
   * @description Método encargado de eliminar la sucursal
   * @version 1.0
   */
  const deleteBranch = async () => {
    if (iconIndex === 0) return setSnack([31, constant.error]);
    if (iconIndex === 2) return setSnack([30, constant.error]);
    setLoading(true);
    try {
      console.log("Eliminado la sucursal...");
      // Se obtiene la sucursal ha eliminar
      const branch = snapshotBranches.find((doc) => doc.id === id);
      // Se elimina el logo
      if (branch.data().logo) await deleteAnyFile(endpoints.storeLogo(administrator, id, branch.data().logoName));
      // Se manda a eliminar las colecciones de la sucursal
      const result1 = await deleteCollections(endpoints.users(administrator, id), administrator, id);
      const result2 = await deleteCollections(endpoints.subscriptions(administrator, id), administrator, id);
      // Se manda a eliminar los documentos de la sucursal
      await deleteDoc(doc(db, endpoints.branch(administrator, id)));
      if (result1.error || result2.error) return setSnack([34, constant.error]);
      // Continua si la eliminación fue satisfactoria
      console.log("Cierra el modal");
      setSnack([33, constant.success]);
      setOpenModalToDelete(false);
    } catch (error) {
      console.log(error);
      setSnack([32, constant.error]);
    }
    setLoading(false);
  };

  const showModalEditBranch = () => {
    const branch = snapshotBranches.find((doc) => doc.id === id);
    if (!branch) return setSnack([64, constant.error]);
    setImg({ logo: branch.data().logo, logoName: branch.data().logoName });
    setDataBranch(branch.data());
    setOpenModalToEditBranch(true);
  };

  /**
   * @name editBranch
   * @description Método encargado de editar una sucursal
   * @version 1.0
   */
  const editBranch = async () => {
    // Valida si inició como administrador para poder crear sucursales
    if (entryType !== 0) return setSnack([27, constant.error]);
    // Valida que no se haya presionado el botón de crear sucursal
    if (loading) return;

    let data = { ...dataBranch };

    if (!data.identification) {
      ideFocus.current.focus();
      setSnack([17, constant.error]);
      return;
    }
    if (!data.name) {
      nameFocus.current.focus();
      setSnack([18, constant.error]);
      return;
    }
    if (data.electronicBill && data.activityCode.code === "0") {
      setSnack([22, constant.error]);
      return;
    }
    if (data.electronicBill && !data.email) {
      emailFocus.current.focus();
      setSnack([1, constant.error]);
      return;
    }
    if (data.electronicBill && !data.otherAddress) {
      data = { ...data, otherAddress: `${data.province.name}, ${data.canton.name}, ${data.district.name}.` };
    }
    if (!data.electronicBill) data = { ...data, ...defaultDataBranch(lang) };

    // Se respalda la imagen antes de borrar la propiedad de la sucursal
    const file = data.file;

    if (!data.logo) data.logoName = "";

    setLoading(true);
    deleteDataBranch(data);
    const response = await callEditBranch(user, data, administrator, id);
    validateResponse(response, file);
  };

  /**
   * @name validateResponse
   * @description Valida la respuesta al editar una sucursal
   * @param {Object} response
   * @param {File} file
   */
  const validateResponse = async (response, file) => {
    if (response.error) {
      setSnack([response.errorCode, constant.error]);
    } else {
      if (file) await storeLogo(id, file);
      if (img.logo && !dataBranch.logo) await deleteAnyFile(endpoints.storeLogo(user.uid, id, img.logoName));
      setDataBranch(defalutBranch);
      setOpenModalToEditBranch(false);
      setSnack([67, constant.success]);
    }
    setLoading(false);
  };

  /**
   * @name storeLogo
   * @description Método que almacena el logo en el Storage y actualiza la ruta en la sucursal
   * @param {String} branchId
   * @param {File} file
   * @version 1.0
   */
  const storeLogo = async (branchId, file) => {
    const fileName = v4();
    const documentURL = await uploadAnyFile(endpoints.storeLogo(user.uid, branchId, fileName), file);
    if (!documentURL.error) {
      await updateDoc(doc(db, endpoints.branch(user.uid, branchId)), { logo: documentURL.url, logoName: fileName });
      if (img.logo) await deleteAnyFile(endpoints.storeLogo(user.uid, id, img.logoName));
    }
  };

  /**
   * @name editBranchHandleClose
   * @description Método que se envía como callBack para que sea ejecutada en el evento de cerrar el modal, limpia los datos de la sucural.
   * @version 1.0
   */
  const editBranchHandleClose = () => setDataBranch(defalutBranch);

  /**
   * @description Objeto para la creación del modal Eliminar Sucursal
   * @version 1.0
   */
  const parameters = {
    btnText: lang.delete,
    icon: <DeleteForever />,
    title: lang.deleteBranch,
    content: <DeleteBranch data={data} setData={setData} consecutive={consecutive} iconIndex={iconIndex} />,
  };

  /**
   * @description Objeto para la creación del modal fullScreen de suscripciones
   * @version 1.0
   */
  const subscriptionParameters = {
    title: lang.subscriptions,
    icon: <ShoppingCartCheckout />,
    content: <Subscriptions consecutiveBranch={consecutive} administrator={administrator} branchId={id} />,
  };

  /**
   * @description Objeto para la creación del modal fullScreen de suscripciones
   * @version 1.0
   */
  const manageUsersParameters = {
    icon: <Engineering />,
    title: lang.manageUsers,
    content: <ManageUsers consecutiveBranch={id} administrator={administrator} />,
  };

  /**
   * @description Objeto para la creación del modal Crear Sucursal
   */
  const editBranchParameters = {
    icon: <Edit />,
    btnText: lang.edit,
    title: lang.editBranch + ": " + consecutive,
    content: <CreateBranch ideFocus={ideFocus} nameFocus={nameFocus} emailFocus={emailFocus} setLoading={setLoading} />,
  };

  /**
   * Efecto para validar los códigos ingresados en los 3 inputs
   */
  useEffect(() => {
    const inputCodes = data.cod1 + data.cod2 + data.cod3;
    if (inputCodes === consecutive) return setIconIndex(1);
    if (data.cod1 === "" || data.cod2 === "" || data.cod3 === "") return setIconIndex(0);
    if (data.cod1 !== "" && data.cod2 !== "" && data.cod3 !== "" && inputCodes !== consecutive) return setIconIndex(2);
  }, [data]);

  return (
    <div>
      <Menu
        open={open}
        anchorEl={anchorEl}
        slotProps={css.menu}
        onClose={handleClose}
        onClick={handleClose}
        anchorOrigin={css.anchor}
        transformOrigin={css.transform}
      >
        <Item css={css.user} action={showModalMangeUsers} icon={<Engineering fontSize="small" />} text={lang.manageUsers} />
        <Item css={css.sub} action={showModalSubscriptions} icon={<AddShoppingCart fontSize="small" />} text={lang.subscriptions} />
        <Divider />
        <Item css={css.edit} action={showModalEditBranch} icon={<Edit fontSize="small" />} text={lang.editBranch} />
        <Item css={css.delete} action={showModalToDeleteBranch} icon={<DeleteForever fontSize="small" />} text={lang.deleteBranch} />
      </Menu>
      <Modal
        snack={snack}
        loading={loading}
        setSnack={setSnack}
        icon={parameters.icon}
        title={parameters.title}
        open={openModalToDelete}
        colorBar={constant.error}
        clickBtnOk={deleteBranch}
        color={constant.deleteColor}
        btnText={parameters.btnText}
        content={parameters.content}
        setOpen={setOpenModalToDelete}
      />
      <ModalFullScreen
        show={openModalSubscriptions}
        icon={subscriptionParameters.icon}
        setShow={setOpenModalSubscriptions}
        title={subscriptionParameters.title}
        content={subscriptionParameters.content}
      />
      <ModalFullScreen
        show={openModalManageUsers}
        icon={manageUsersParameters.icon}
        setShow={setOpenModalManageUsers}
        title={manageUsersParameters.title}
        content={manageUsersParameters.content}
      />
      <Modal
        snack={snack}
        loading={loading}
        setSnack={setSnack}
        clickBtnOk={editBranch}
        open={openModalToEditBranch}
        icon={editBranchParameters.icon}
        setOpen={setOpenModalToEditBranch}
        title={editBranchParameters.title}
        btnText={editBranchParameters.btnText}
        content={editBranchParameters.content}
        callBackHandleClose={editBranchHandleClose}
      />
      <Message snack={snack}></Message>
    </div>
  );
};

/**
 * @name styles
 * @description Método encargado de devolver los estilos a los componentes
 * @returns Object
 * @version 1.0
 */
const styles = () => {
  return {
    menu: {
      elevation: 0,
      sx: {
        mt: 1.5,
        overflow: "visible",
        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
        "& .MuiAvatar-root": {
          mr: 1,
          ml: -0.5,
          width: 32,
          height: 32,
        },
        "&:before": {
          top: 0,
          zIndex: 0,
          right: 14,
          width: 10,
          height: 10,
          content: '""',
          display: "block",
          position: "absolute",
          bgcolor: "background.paper",
          transform: "translateY(-50%) rotate(45deg)",
        },
      },
    },
    sub: { color: "#6AA5DC" },
    user: { color: "#24ad62" },
    edit: { color: "#AFA73B" },
    delete: { color: "#A7524C" },
    anchor: { horizontal: "right", vertical: "bottom" },
    transform: { horizontal: "right", vertical: "top" },
  };
};

export default BranchCardMenu;
