import { Box, IconButton } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { v4 } from "uuid";

/**
 * @name GenericGridType2
 * @description Método que devuelve una tabla con filas sencillas (no desplegables), y con botones de acción
 * @param {Object} { rowHeight: Number, columns: Array, rows: Array, actions: Array, setSelectedRows: useState }
 * @returns View
 */
const GenericGridType2 = ({ rowHeight, columns, rows, actions, setSelectedRows }) => {
  const css = styles();

  /**
   * @name renderDetailsButton
   * @description Método que redenderiza los botones de las acciones de la fila
   * @param {Object} params
   * @returns View
   * @version 1.0
   */
  const renderDetailsButton = (params) => {
    const buttons = actions.reduce((acc, element) => {
      acc.push(
        <IconButton key={v4()} onMouseUp={() => element.callBack(params)} sx={{ color: element.color }}>
          {element.icon}
        </IconButton>
      );
      return acc;
    }, []);
    return <strong>{buttons}</strong>;
  };

  /**
   * @name getColumns
   * @description Método que retorna un arreglo con las columnas de la tabla
   * @returns {Array}
   * @version 1.0
   */
  const getColumns = () => {
    const elements = columns.reduce((acc, element, index) => {
      let item = { field: element.field, headerName: element.headerName, flex: element.flex, editable: element.editable };
      // Valida si la tabla requiere acciones y si está en la última columna para agregar los botones
      if (index === columns.length - 1 && element.field === "action") item = { ...item, sortable: false, renderCell: renderDetailsButton };
      acc.push(item);
      return acc;
    }, []);
    return elements;
  };

  return (
    <Box sx={css.main}>
      <DataGrid
        hideFooter
        rows={rows}
        checkboxSelection
        rowHeight={rowHeight}
        columns={getColumns()}
        disableRowSelectionOnClick
        localeText={{ noRowsLabel: "" }}
        onRowSelectionModelChange={(params) => setSelectedRows(params)}
      />
    </Box>
  );
};

/**
 * @name styles
 * @description Método encargado de devolver los estilos a los componentes
 * @returns {Object}
 * @version 1.0
 */
const styles = () => {
  return {
    main: { width: "100%" },
  };
};

export default GenericGridType2;
