import { Edit, DeleteForever } from "@mui/icons-material";
import { useThis } from "../../../assets/context/Context";
import GenericGridType2 from "../grids/GenericGridType2";
import getConstant from "../../../assets/js/Constant";
import HeaderProducts from "../HeaderProducts";
import LoadingBar from "../LoadingBar";
import { useState } from "react";

const ProductsExpress = () => {
  const css = styles();
  const { lang } = useThis();
  const constant = getConstant();

  const [row, setRow] = useState({});
  const [rows, setRows] = useState([]);
  const [loadingBar, setLoadingBar] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);

  const filterExpress = (e) => {};

  const showCreateExpress = () => {};

  /**
   * @name actionRowDelete
   * @description Método que maneja el evento del botón eliminar de cada fila
   * @param {Object} params
   * @version 1.0
   */
  const actionRowDelete = (params) => {
    setRow(params.row);
    //setOpenModalToDelete(true);
  };

  /**
   * @name editUser
   * @description Método que maneja el evento del botón editar de cada fila
   * @param {Object} params
   * @version 1.0
   */
  const actionRowEdit = (params) => {
    /*setEdit(true);
    setOpenCreateUser(true);
    setData(params.row.data);
    setIdToEdit(params.row.id);
    setDataPermissions(params.row.data.permissions);*/
  };

  /** Constante que define las columnas para la tabla */
  const columns = [
    { field: "express", headerName: lang.express, flex: 1 },
    { field: "price", headerName: lang.price, flex: 1 },
    { field: "tax", headerName: lang.tax, flex: 1 },
    { field: "finalPrice", headerName: lang.finalPrice, flex: 1 },
    { field: "edition", headerName: lang.edition, flex: 1 },
    { field: "action", headerName: lang.actions, flex: 0 },
  ];

  /** Constante que almacena los datos para los botones de acciones de cada fila */
  const actions = [
    { icon: <Edit />, callBack: actionRowEdit, color: constant.editColor },
    { icon: <DeleteForever />, callBack: actionRowDelete, color: constant.deleteColor },
  ];

  return (
    <div style={css.box}>
      <HeaderProducts title={lang.manageExpress} toolTip={lang.addExpress} search={filterExpress} button={showCreateExpress} icons={3} />
      <LoadingBar visible={loadingBar} />
      <div style={css.containerGrid}>
        <GenericGridType2 rowHeight={40} columns={columns} rows={rows} actions={actions} setSelectedRows={setSelectedRows} />
      </div>
    </div>
  );
};

/**
 * @name styles
 * @description Método encargado de devolver los estilos a los componentes
 * @returns Object
 * @version 1.0
 */
const styles = () => {
  return {
    box: { width: "100%" },
    containerGrid: { flex: "1", overflowY: "auto" },
  };
};

export default ProductsExpress;
