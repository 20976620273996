import { useThis } from "../../../assets/context/Context";
import HeaderProducts from "../HeaderProducts";

const ProductsAndServices = () => {
  const css = styles();
  const { lang } = useThis();

  const filterProdAndServ = (e) => {};

  const showCreateProdAndServ = () => {};

  return (
    <div style={css.box}>
      <HeaderProducts title={lang.manageProdAndServ} toolTip={lang.addProdAndServ} search={filterProdAndServ} button={showCreateProdAndServ} icons={1} />
    </div>
  );
};

/**
 * @name styles
 * @description Método encargado de devolver los estilos a los componentes
 * @returns Object
 * @version 1.0
 */
const styles = () => {
  return {
    box: { width: "100%" },
  };
};

export default ProductsAndServices;
