import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import { useThis } from "../../assets/context/Context.js";
import StepperHorizontal from "./StepperHorizontal.js";
import getConstant from "../../assets/js/Constant.js";
import { useEffect, useRef, useState } from "react";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import StepperVertical from "./StepperVertical.js";
import ForgotPassword from "./ForgotPassword.js";
import IconMicrosoft from "./IconMicrosoft.js";
import LoadingButton from "./LoadingButton";
import AccountButton from "./AccountButton";
import SelectEntry from "./SelectEntry.js";
import IconGoogle from "./IconGoogle.js";
import InputCheck from "./InputCheck";
import InputText from "./InputText";
import Message from "./Message.js";
import Logo from "./Logo";
import { test } from "../../assets/js/firebaseMethods.js";

/**
 * @name Login
 * @description Método encargado de mostrar el inicio de sesión
 * @returns View
 * @version 1.0
 */
const Login = () => {
  const constant = getConstant();
  const { lang, setForm, data, setData, user, setStep, signInWithEmail, signInWithOtherProvider, isMobile, setYearText } = useThis();

  const [snack, setSnack] = useState([]); // [Índice 0 = código del mensaje, Índice 1 = tipo de mensaje]
  const [display, setDisplay] = useState("none");
  const [loading, setLoading] = useState(false);
  const [textBtn, setTextBtn] = useState(lang.continue);

  const css = styles(display, constant.primaryColor);

  const emailFocus = useRef(null);
  const passwordFocus = useRef(null);

  /**
   * @name loginWithEmail
   * @description Método encargado de iniciar sesión con un correo
   * @param {Event} e
   */
  const loginWithEmail = (e) => {
    e.preventDefault();
    if (loading) return;
    // Valida el correo
    if (!data.email) {
      emailFocus.current.focus();
      return setSnack([1, constant.error]);
    }
    // Se muestra el input para la contraseña y se ocultan otros componentes
    if (display === "none") {
      setDisplay("flex");
      setTextBtn(lang.login);
      return;
    }
    // Se valida la contraseña
    if (!data.password) {
      passwordFocus.current.focus();
      return setSnack([2, constant.error]);
    }
    // Valida que el correo haya sido verificado
    if (user && !user.emailVerified) return setSnack([11, constant.warning]);

    // Se llama al método para iniciar la sesión
    setLoading(true);
    signInWithEmail(data.email, data.password, data.remember)
      .then(() => {
        setForm(<SelectEntry />);
      })
      .catch((error) => {
        switch (error.code) {
          case "auth/invalid-email":
            setSnack([6, constant.error]);
            break;
          case "auth/network-request-failed":
            setSnack([16, constant.error]);
            break;
          case "auth/too-many-requests":
            setSnack([13, constant.error]);
            break;
          default:
            // Muestra el error y limpia la contraseña
            passwordFocus.current.focus();
            setSnack([15, constant.error]);
            setData({ ...data, password: "" });
            break;
        }
      })
      .finally(() => setLoading(false));
  };

  /**
   * @name loginWithOtherProvider
   * @description Método encargado de iniciar sesión con una cuenta de terceros
   * @param {Number} providerCode - 1 = Google 2 = Microsoft
   */
  const loginWithOtherProvider = (providerCode) => {
    signInWithOtherProvider(providerCode)
      .then(() => {})
      .catch(() => {});
  };

  /**
   * @name showRegister
   * @description Método que muestra el proceso de Registro de acuerdo al tipo de dispositivo
   */
  const showRegister = () => {
    // Llama al proceso de registro inicializando las mismas variables de login para su uso
    cleanData();
    const steps = [lang.user, lang.credentials, lang.verification];
    if (isMobile) {
      setForm(<StepperVertical steps={steps} />);
    } else {
      setForm(<StepperHorizontal steps={steps} />);
    }
  };

  /**
   * @name cleanData
   * @description Método encargado de limpiar los datos de ingreso y registro
   */
  const cleanData = () => {
    setStep(0);
    setData({ email: "", password: "", userName: "", remember: false });
  };

  useEffect(() => {
    cleanData();
    // Establece la letra en el avatar y el color de estado
    if (user && !user.emailVerified) setSnack([11, constant.warning]);
  }, [user]);

  /**
   * @description Efecto que muestra el texto al pié de página
   */
  useEffect(() => {
    setYearText("block");
  }, []);

  return (
    <div style={css.container}>
      <Logo type={2} size={200} />
      <form>
        <InputText
          data={data}
          name="email"
          marginTop={3}
          size={"small"}
          type={"email"}
          width={"300px"}
          setData={setData}
          value={data.email}
          label={lang.email}
          referece={emailFocus}
          icon={<AlternateEmailIcon />}
        />
        <InputText
          data={data}
          marginTop={1}
          size={"small"}
          name="password"
          width={"300px"}
          type={"password"}
          display={display}
          setData={setData}
          label={lang.password}
          value={data.password}
          icon={<VpnKeyIcon />}
          referece={passwordFocus}
        />
        <LoadingButton loading={loading} marginTop={2} text={textBtn} handleClick={loginWithEmail} width={"300px"} type={"submit"} />
      </form>
      <div style={css.remember}>
        <div style={css.containerCheck}>
          <InputCheck name={"remember"} data={data} setData={setData} />
          <p style={css.rememberTex}>{lang.remember}</p>
        </div>
        <p className="clickText" style={css.forgotTex} onClick={() => setForm(<ForgotPassword />)}>
          {lang.forgotText}
        </p>
      </div>
      <div style={css.accountButtonsGroup}>
        <div style={css.containerOr}>
          <p style={css.or}>{lang.or}</p>
        </div>
        <div>
          <AccountButton mt={0} width={"300px"} icon={<IconGoogle />} text={lang.btnGoogle} handleClick={() => loginWithOtherProvider(1)} />
          <AccountButton mt={1} mb={2} width={"300px"} icon={<IconMicrosoft />} text={lang.btnMicrosoft} handleClick={() => loginWithOtherProvider(2)} />
          <br />
          {constant.test && <button onClick={() => console.log(test(user))}>Test</button>}
        </div>
      </div>
      <div style={css.signUp}>
        <p style={css.text}>{lang.haveAccount}</p>
        <p className="clickText" style={css.signUpText} onClick={showRegister}>
          {lang.register}
        </p>
      </div>
      <Message snack={snack}></Message>
    </div>
  );
};

/**
 * @name styles
 * @description Método encargado de devolver los estilos a los componentes
 * @param {String} display - fex ó none
 * @param {String} primaryColor
 * @returns Object
 */
const styles = (display, primaryColor) => {
  return {
    containerOr: {
      padding: "0",
      display: "flex",
      position: "relative",
      marginBottom: "15px",
      alignItems: "center",
      justifyContent: "center",
      borderBottom: "1px solid #CCC",
    },
    text: { fontSize: "14px", color: "#4b4b4b" },
    containerCheck: { display: "flex", alignItems: "center" },
    rememberTex: { fontSize: "14px", marginLeft: "2px", color: "#4b4b4b" },
    accountButtonsGroup: { display: display === "flex" ? "none" : "block" },
    signUpText: { fontSize: "14px", fontWeight: "500", cursor: "pointer", color: "#4b4b4b" },
    container: { display: "flex", flexDirection: "column", alignItems: "center", position: "relative" },
    remember: { width: "100%", display: "flex", justifyContent: "space-between", margin: "15px 0 10px 0" },
    or: { padding: "5px", position: "absolute", lineHeight: "12px", fontSize: "12px", background: "#FBFFFE", borderRadius: "50px" },
    signUp: { width: "100%", display: "flex", justifyContent: "space-between", borderTop: "1px solid #CCCCCC", paddingTop: "10px" },
    forgotTex: { fontSize: "14px", marginLeft: "2px", color: primaryColor, cursor: "pointer", display: display === "flex" ? "block" : "none" },
  };
};

export default Login;
