import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { useThis } from "../../../assets/context/Context";
import { Fragment, useEffect, useState } from "react";
import IconButton from "@mui/material/IconButton";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Collapse from "@mui/material/Collapse";
import Table from "@mui/material/Table";
import Box from "@mui/material/Box";
import Label from "../Label";

/**
 * @name GenericGridType1
 * @description Método encargado de crear un componente de tipo Grid dinámicamente, tabla con filas desplegables (dobles)
 * @param {Object} { columns, subColumns, subTitle, rows, subRows }
 * @returns View
 */
const GenericGridType1 = ({ columns, subColumns, subTitle, rows, subRows }) => {
  const css = styles();
  const { isMobile } = useThis();
  const [gridRows, setGridRows] = useState([]);

  useEffect(() => {
    /** Obtener los datos de cada fila */
    const viewRows = rows.reduce((acc, doc, index) => {
      acc.push(
        <CreateRow key={"ROW_ID_" + index} subColumns={subColumns} subRow={subRows[index]} isMobile={isMobile} css={css} row={doc} subTitle={subTitle} />
      );
      return acc;
    }, []);
    setGridRows(viewRows);
  }, [rows]);

  return (
    <Table>
      <TableHead sx={css.tableHead}>
        <TableRow>{getCells("COLUMNS_", columns, isMobile, true, true)}</TableRow>
      </TableHead>
      <TableBody>{gridRows}</TableBody>
    </Table>
  );
};

/**
 * @name CreateRow
 * @description Método encargado de crear las filas de la vista
 * @param {Object} { subColumns, row, subRow, isMobile, subTitle, css }
 * @returns View
 */
const CreateRow = ({ subColumns, row, subRow, isMobile, subTitle, css }) => {
  const [open, setOpen] = useState(false);
  const iconArrow = getRowIconButton(open, setOpen, css);
  // Coloca el ícono al inicio del array
  const auxRow = [iconArrow, ...row];
  return <Row subColumns={subColumns} subRow={subRow} isMobile={isMobile} css={css} open={open} row={auxRow} subTitle={subTitle} />;
};

/**
 * @name Row
 * @description Método que devuelve un componente de tipo fila
 * @param {Object} { subColumns, subRow, isMobile, css, open, row, subTitle }
 * @returns View
 */
const Row = ({ subColumns, subRow, isMobile, css, open, row, subTitle }) => {
  return (
    <Fragment>
      <TableRow sx={css.tableRow}>{getCells("ROW_", row, isMobile, true, false)}</TableRow>
      <TableRow>
        <TableCell style={css.tc} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={css.box}>
              <Label text={subTitle} fontWeight={600} fontSize="16px" />
              <Table size="small">
                <TableHead>
                  <TableRow>{getCells("SUB_COLUMNS_", subColumns, isMobile, false, false)}</TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>{getCells("SUB_ROW_", subRow, isMobile, false, false)}</TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </Fragment>
  );
};

/**
 * @name getRowIconButton
 * @description Método que devuelve un ícono de tipo botón para manejar el despliegue de cada fila
 * @param {useState} open boolean
 * @param {useState} setOpen boolean
 * @param {Object} css
 * @returns View
 */
const getRowIconButton = (open, setOpen, css) => {
  return (
    <IconButton aria-label="expand row" size="small" sx={css.iconButton} onClick={() => setOpen(!open)}>
      {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
    </IconButton>
  );
};

/**
 * @name getCells
 * @description Método encargado de colocar los datos en las celdas de cada fila
 * @param {String} prefix
 * @param {Array} elements
 * @param {Boolean} isMobile
 * @param {Boolean} hasIcon
 * @param {Boolean} isHead
 * @returns View
 */
const getCells = (prefix, elements, isMobile, hasIcon, isHead) => {
  const css = styles(isHead, hasIcon);
  return elements.map((element, index, array) => {
    let configCells = { ...css.configCells };
    if (index === 0) {
      configCells = { ...configCells, ...css.first };
    } else if (index === array.length - 1) {
      configCells.textAlign = "right";
    } else {
      // Valida si es móvil y permite el segundo elemento
      if (isMobile && index > 1) return null;
    }
    return (
      <TableCell key={prefix + index} sx={configCells}>
        {element}
      </TableCell>
    );
  });
};

/**
 * @name styles
 * @description Método encargado de devolver los estilos a los componentes
 * @returns Object
 * @version 1.0
 */
const styles = (isHead = false, hasIcon = false) => {
  return {
    box: { margin: 1 },
    tc: { padding: "0px" },
    tableHead: { width: "auto", height: "24px" },
    iconButton: { width: "auto", padding: "0px" },
    tableRow: { "& > *": { borderBottom: "unset" }, background: "#f8f8f8" },
    first: { width: hasIcon ? "24px" : "auto", padding: "5px 0px 5px 15px" },
    configCells: { width: "auto", padding: "5px 15px", textAlign: "left", fontWeight: isHead ? "600" : "normal" },
  };
};

export default GenericGridType1;
