import FolderSpecialIcon from "@mui/icons-material/FolderSpecial";
import { useThis } from "../../assets/context/Context";
import getConstant from "../../assets/js/Constant";
import InputFileUpload from "./InputFileUpload";
import defalut from "../../images/default.svg";
import InputText from "./InputText";

/**
 * @name AddCategory
 * @description Muestra los componentes del modal para agregar categorías
 * @param {Object} { data, setData }
 * @returns View
 * @version 1.0
 */
const AddCategory = ({ data, setData }) => {
  const constant = getConstant();
  const css = styles();
  const { lang } = useThis();
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <InputText
        data={data}
        type={"text"}
        size={"small"}
        width={"auto"}
        marginBottom={1}
        setData={setData}
        name={"category"}
        label={lang.category}
        value={data.category}
        icon={<FolderSpecialIcon />}
      />
      <img src={data.document ? data.document : defalut} alt="" style={css.img} />
      <InputFileUpload name="document" text={lang.image} accept={constant.onlyImage} color={"#666"} data={data} setData={setData} />
    </div>
  );
};

/**
 * @name styles
 * @description Método encargado de devolver los estilos a los componentes
 * @returns Object
 * @version 1.0
 */
const styles = () => {
  return {
    img: { width: "280px", height: "280px ", border: "1px solid #CCC", marginBottom: "3px", objectFit: "cover" },
  };
};

export default AddCategory;
