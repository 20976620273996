import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import { useThis } from "../../../assets/context/Context";
import { Edit, DeleteForever } from "@mui/icons-material";
import GenericGridType2 from "../grids/GenericGridType2";
import getConstant from "../../../assets/js/Constant";
import HeaderProducts from "../HeaderProducts";
import Modal from "../../containers/Modal";
import AddCategory from "../AddCategory";
import LoadingBar from "../LoadingBar";
import { useState } from "react";

const ProductsCategories = () => {
  const css = styles();
  const { lang } = useThis();
  const constant = getConstant();

  const [row, setRow] = useState({});
  const [rows, setRows] = useState([]);
  const [data, setData] = useState({});
  const [snack, setSnack] = useState([]); // [Índice 0 = código del mensaje, Índice 1 = tipo de mensaje]
  const [edit, setEdit] = useState(false);
  const [idToEdit, setIdToEdit] = useState("");
  const [loading, setLoading] = useState(false); // Para el modal
  const [loadingBar, setLoadingBar] = useState(false); // Para la tabla
  const [selectedRows, setSelectedRows] = useState([]);
  const [openAddCategory, setOpenAddCategory] = useState(false);

  /**
   * @name filter
   * @description Método que filtra las caterías de la tabla, por nombre
   * @param {Event} e
   * @version 1.0
   */
  const filter = (e) => {};

  /**
   * @name showCreateCategories
   * @description Método que se encarga de mostrar el modal para agregar o editar categorías
   * @version 1.0
   */
  const showCreateCategories = () => {
    setEdit(false);
    /** Inicializa los datos */
    setData(resetData());
    /** Muestra el modal para crear o ediar categorías */
    setOpenAddCategory(true);
  };

  /**
   * @name actionRowDelete
   * @description Método que maneja el evento del botón eliminar de cada fila
   * @param {Object} params
   * @version 1.0
   */
  const actionRowDelete = (params) => {
    setRow(params.row);
    //setOpenModalToDelete(true);
  };

  /**
   * @name editUser
   * @description Método que maneja el evento del botón editar de cada fila
   * @param {Object} params
   * @version 1.0
   */
  const actionRowEdit = (params) => {
    setEdit(true);
    setOpenAddCategory(true);
    setData(params.row.data);
    setIdToEdit(params.row.id);
    //setDataPermissions(params.row.data.permissions);
  };

  /**
   * @name addCategory
   * @description Método que ejecuta el llamado al servicio para agregar una categoría
   * @version 1.0
   */
  const addCategory = () => {
    console.log(data);
  };

  /**
   * @name editCategory
   * @description Método que ejecuta el llamado al servicio para editar una categoría
   * @version 1.0
   */
  const editCategory = () => {};

  /**
   * @name resetData
   * @description Método que inicializa los datos del usuarrio
   * @version 1.0
   */
  const resetData = () => {
    return {
      category: "",
      document: "",
    };
  };

  /** Constante que define las columnas para la tabla */
  const columns = [
    { field: "category", headerName: lang.category, flex: 1 },
    { field: "image", headerName: lang.image, flex: 1 },
    { field: "date", headerName: lang.edition, flex: 1 },
    { field: "action", headerName: lang.actions, flex: 0 },
  ];

  /** Constante que almacena los datos para los botones de acciones de cada fila */
  const actions = [
    { icon: <Edit />, callBack: actionRowEdit, color: constant.editColor },
    { icon: <DeleteForever />, callBack: actionRowDelete, color: constant.deleteColor },
  ];

  /**
   * @description Objeto para la creación del modal agregar o editar categorías
   */
  const parametersToAddCategory = {
    btnText: edit ? lang.edit : lang.add,
    icon: edit ? <Edit /> : <CreateNewFolderIcon />,
    title: edit ? lang.editCategory : lang.newCategory,
    content: <AddCategory data={data} setData={setData} />,
  };

  return (
    <div style={css.box}>
      <HeaderProducts title={lang.manageCategories} toolTip={lang.addCategory} actionSearch={filter} actionButton={showCreateCategories} icons={0} />
      <LoadingBar visible={loadingBar} />
      <div style={css.containerGrid}>
        <GenericGridType2 rowHeight={40} columns={columns} rows={rows} actions={actions} setSelectedRows={setSelectedRows} />
      </div>
      <Modal
        snack={snack}
        loading={loading}
        setSnack={setSnack}
        open={openAddCategory}
        setOpen={setOpenAddCategory}
        icon={parametersToAddCategory.icon}
        title={parametersToAddCategory.title}
        btnText={parametersToAddCategory.btnText}
        content={parametersToAddCategory.content}
        clickBtnOk={edit ? editCategory : addCategory}
      />
    </div>
  );
};

/**
 * @name styles
 * @description Método encargado de devolver los estilos a los componentes
 * @returns Object
 * @version 1.0
 */
const styles = () => {
  return {
    box: { width: "100%" },
    containerGrid: { flex: "1", overflowY: "auto" },
  };
};

export default ProductsCategories;
